<script lang="ts">
    import { page } from '$app/stores';
    import { Sidebar, SidebarGroup, SidebarItem, SidebarWrapper } from '@allibee/components/basic';
    import { getContext } from 'svelte';
    import { _ } from 'svelte-i18n';
    import type { Readable } from 'svelte/store';

    const { transformUrl } = $page.data.master;

    // src/routes/[[lang=locale]]/(need-auth)/+layout.svelte 의 GNB 토글의 상태를 context로 내려주고 있음.
    const isFoldedState = getContext<Readable<boolean>>('isFoldedState');
</script>

<Sidebar isFolded={$isFoldedState} class="top-10 border-r border-gray-200 bg-gray-100">
    <!-- Sidebar 의 header slot -->
    <div slot="header">
        <div class="relative flex shrink-0 flex-row items-center justify-start gap-2 self-stretch rounded-lg pb-2 pt-2">
            <div class="relative flex flex-1 flex-row items-center justify-start gap-1">
                <div class="Pages font-['Pretendard'] text-base font-semibold leading-normal text-neutral-900">개발용 LNB</div>
            </div>
        </div>
    </div>

    <!-- Sidebar 의 slot -->
    <SidebarWrapper>
        <SidebarGroup>
            <!-- 홈(런처)) -->
            <SidebarItem label={'런처'} href={transformUrl('/')}>
                <svelte:fragment slot="icon">
                    <svg width="1.125rem" height="1.125rem" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.6668 9.33333V20C10.6668 20.7072 10.9478 21.3855 11.4479 21.8856C11.948 22.3857 12.6263 22.6667 13.3335 22.6667H21.3335M10.6668 9.33333V6.66667C10.6668 5.95942 10.9478 5.28115 11.4479 4.78105C11.948 4.28095 12.6263 4 13.3335 4H19.4482C19.8018 4.00008 20.1408 4.1406 20.3908 4.39067L26.2762 10.276C26.5262 10.526 26.6668 10.8651 26.6668 11.2187V20C26.6668 20.7072 26.3859 21.3855 25.8858 21.8856C25.3857 22.3857 24.7074 22.6667 24.0002 22.6667H21.3335M10.6668 9.33333H8.00016C7.29292 9.33333 6.61464 9.61428 6.11454 10.1144C5.61445 10.6145 5.3335 11.2928 5.3335 12V25.3333C5.3335 26.0406 5.61445 26.7189 6.11454 27.219C6.61464 27.719 7.29292 28 8.00016 28H18.6668C19.3741 28 20.0524 27.719 20.5524 27.219C21.0525 26.7189 21.3335 26.0406 21.3335 25.3333V22.6667"
                            stroke="#606469"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </svelte:fragment>
            </SidebarItem>
            <!-- my reqeust -->
            <SidebarItem label={'계약 목록'} href={transformUrl('/1-1/clm/my-request/')}>
                <svelte:fragment slot="icon">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.75 9.5H11.25M6.75 12.5H11.25M12.75 16.25H5.25C4.85218 16.25 4.47064 16.092 4.18934 15.8107C3.90804 15.5294 3.75 15.1478 3.75 14.75V4.25C3.75 3.85218 3.90804 3.47064 4.18934 3.18934C4.47064 2.90804 4.85218 2.75 5.25 2.75H9.4395C9.6384 2.75004 9.82913 2.82909 9.96975 2.96975L14.0303 7.03025C14.1709 7.17087 14.25 7.3616 14.25 7.5605V14.75C14.25 15.1478 14.092 15.5294 13.8107 15.8107C13.5294 16.092 13.1478 16.25 12.75 16.25Z"
                            stroke="#606469"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </svelte:fragment>
            </SidebarItem>
            <!-- contract hub -->
            <SidebarItem label={'conHub'} href={transformUrl('/1-1/clm/conhub/')}>
                <svelte:fragment slot="icon">
                    <svg width="1.125rem" height="1.125rem" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.6668 9.33333V20C10.6668 20.7072 10.9478 21.3855 11.4479 21.8856C11.948 22.3857 12.6263 22.6667 13.3335 22.6667H21.3335M10.6668 9.33333V6.66667C10.6668 5.95942 10.9478 5.28115 11.4479 4.78105C11.948 4.28095 12.6263 4 13.3335 4H19.4482C19.8018 4.00008 20.1408 4.1406 20.3908 4.39067L26.2762 10.276C26.5262 10.526 26.6668 10.8651 26.6668 11.2187V20C26.6668 20.7072 26.3859 21.3855 25.8858 21.8856C25.3857 22.3857 24.7074 22.6667 24.0002 22.6667H21.3335M10.6668 9.33333H8.00016C7.29292 9.33333 6.61464 9.61428 6.11454 10.1144C5.61445 10.6145 5.3335 11.2928 5.3335 12V25.3333C5.3335 26.0406 5.61445 26.7189 6.11454 27.219C6.61464 27.719 7.29292 28 8.00016 28H18.6668C19.3741 28 20.0524 27.719 20.5524 27.219C21.0525 26.7189 21.3335 26.0406 21.3335 25.3333V22.6667"
                            stroke="#606469"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </svelte:fragment>
            </SidebarItem>

            <!-- new contract -->
            <SidebarItem label="새 계약서" href={transformUrl('/1-1/clm/new-contract/standard-form/1/')}>
                <svelte:fragment slot="icon">
                    <svg width="1.125rem" height="1.125rem" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.6668 9.33333V20C10.6668 20.7072 10.9478 21.3855 11.4479 21.8856C11.948 22.3857 12.6263 22.6667 13.3335 22.6667H21.3335M10.6668 9.33333V6.66667C10.6668 5.95942 10.9478 5.28115 11.4479 4.78105C11.948 4.28095 12.6263 4 13.3335 4H19.4482C19.8018 4.00008 20.1408 4.1406 20.3908 4.39067L26.2762 10.276C26.5262 10.526 26.6668 10.8651 26.6668 11.2187V20C26.6668 20.7072 26.3859 21.3855 25.8858 21.8856C25.3857 22.3857 24.7074 22.6667 24.0002 22.6667H21.3335M10.6668 9.33333H8.00016C7.29292 9.33333 6.61464 9.61428 6.11454 10.1144C5.61445 10.6145 5.3335 11.2928 5.3335 12V25.3333C5.3335 26.0406 5.61445 26.7189 6.11454 27.219C6.61464 27.719 7.29292 28 8.00016 28H18.6668C19.3741 28 20.0524 27.719 20.5524 27.219C21.0525 26.7189 21.3335 26.0406 21.3335 25.3333V22.6667"
                            stroke="#606469"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </svelte:fragment>
            </SidebarItem>
            <!-- elm -->
            <SidebarItem label="ELM" href={transformUrl('/1-1/elm/')}>
                <svelte:fragment slot="icon">
                    <svg width="1.125rem" height="1.125rem" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.6668 9.33333V20C10.6668 20.7072 10.9478 21.3855 11.4479 21.8856C11.948 22.3857 12.6263 22.6667 13.3335 22.6667H21.3335M10.6668 9.33333V6.66667C10.6668 5.95942 10.9478 5.28115 11.4479 4.78105C11.948 4.28095 12.6263 4 13.3335 4H19.4482C19.8018 4.00008 20.1408 4.1406 20.3908 4.39067L26.2762 10.276C26.5262 10.526 26.6668 10.8651 26.6668 11.2187V20C26.6668 20.7072 26.3859 21.3855 25.8858 21.8856C25.3857 22.3857 24.7074 22.6667 24.0002 22.6667H21.3335M10.6668 9.33333H8.00016C7.29292 9.33333 6.61464 9.61428 6.11454 10.1144C5.61445 10.6145 5.3335 11.2928 5.3335 12V25.3333C5.3335 26.0406 5.61445 26.7189 6.11454 27.219C6.61464 27.719 7.29292 28 8.00016 28H18.6668C19.3741 28 20.0524 27.719 20.5524 27.219C21.0525 26.7189 21.3335 26.0406 21.3335 25.3333V22.6667"
                            stroke="#606469"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </svelte:fragment>
            </SidebarItem>
            <!-- 전자 결재 -->
            <SidebarItem label="결재 목록" href={transformUrl('/1-1/clm/approval/')}>
                <svelte:fragment slot="icon">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.75 4.25H5.25C4.85218 4.25 4.47064 4.40804 4.18934 4.68934C3.90804 4.97064 3.75 5.35218 3.75 5.75V14.75C3.75 15.1478 3.90804 15.5294 4.18934 15.8107C4.47064 16.092 4.85218 16.25 5.25 16.25H12.75C13.1478 16.25 13.5294 16.092 13.8107 15.8107C14.092 15.5294 14.25 15.1478 14.25 14.75V5.75C14.25 5.35218 14.092 4.97064 13.8107 4.68934C13.5294 4.40804 13.1478 4.25 12.75 4.25H11.25M6.75 4.25C6.75 4.64782 6.90804 5.02936 7.18934 5.31066C7.47064 5.59196 7.85218 5.75 8.25 5.75H9.75C10.1478 5.75 10.5294 5.59196 10.8107 5.31066C11.092 5.02936 11.25 4.64782 11.25 4.25M6.75 4.25C6.75 3.85218 6.90804 3.47064 7.18934 3.18934C7.47064 2.90804 7.85218 2.75 8.25 2.75H9.75C10.1478 2.75 10.5294 2.90804 10.8107 3.18934C11.092 3.47064 11.25 3.85218 11.25 4.25M9 9.5H11.25M9 12.5H11.25M6.75 9.5H6.7575M6.75 12.5H6.7575"
                            stroke="#606469"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </svelte:fragment>
            </SidebarItem>
            <!-- assignment -->
            <SidebarItem label="법무 담당자 배정" href={transformUrl('/1-1/clm/assignment/')}>
                <svelte:fragment slot="icon">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.75 5.75C9.75 6.54565 9.43393 7.30871 8.87132 7.87132C8.30871 8.43393 7.54565 8.75 6.75 8.75C5.95435 8.75 5.19129 8.43393 4.62868 7.87132C4.06607 7.30871 3.75 6.54565 3.75 5.75C3.75 4.95435 4.06607 4.19129 4.62868 3.62868C5.19129 3.06607 5.95435 2.75 6.75 2.75C7.54565 2.75 8.30871 3.06607 8.87132 3.62868C9.43393 4.19129 9.75 4.95435 9.75 5.75ZM2.25 15.5C2.25 14.3065 2.72411 13.1619 3.56802 12.318C4.41193 11.4741 5.55653 11 6.75 11C7.94347 11 9.08807 11.4741 9.93198 12.318C10.7759 13.1619 11.25 14.3065 11.25 15.5V16.25H2.25V15.5Z"
                            stroke="#606469"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M14.2965 8.75L13.5 7.25L12.75 8.75L11 8.957L12.25 10.0835L12 11.75L13.5 10.8125L15 11.75L14.75 10.0835L16 8.957L14.2965 8.75Z"
                            stroke="#606469"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </svelte:fragment>
            </SidebarItem>
            <SidebarItem label="체결본 보관함" href={transformUrl('/1-1/clm/repository/')}>
                <svelte:fragment slot="icon">
                    <svg width="1.125rem" height="1.125rem" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.6668 9.33333V20C10.6668 20.7072 10.9478 21.3855 11.4479 21.8856C11.948 22.3857 12.6263 22.6667 13.3335 22.6667H21.3335M10.6668 9.33333V6.66667C10.6668 5.95942 10.9478 5.28115 11.4479 4.78105C11.948 4.28095 12.6263 4 13.3335 4H19.4482C19.8018 4.00008 20.1408 4.1406 20.3908 4.39067L26.2762 10.276C26.5262 10.526 26.6668 10.8651 26.6668 11.2187V20C26.6668 20.7072 26.3859 21.3855 25.8858 21.8856C25.3857 22.3857 24.7074 22.6667 24.0002 22.6667H21.3335M10.6668 9.33333H8.00016C7.29292 9.33333 6.61464 9.61428 6.11454 10.1144C5.61445 10.6145 5.3335 11.2928 5.3335 12V25.3333C5.3335 26.0406 5.61445 26.7189 6.11454 27.219C6.61464 27.719 7.29292 28 8.00016 28H18.6668C19.3741 28 20.0524 27.719 20.5524 27.219C21.0525 26.7189 21.3335 26.0406 21.3335 25.3333V22.6667"
                            stroke="#606469"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </svelte:fragment>
            </SidebarItem>
            <SidebarItem label="Stand-alone AI review 2.0" href={transformUrl('/ai-review-2/')}>
                <svelte:fragment slot="icon">
                    <svg width="1.125rem" height="1.125rem" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.6668 9.33333V20C10.6668 20.7072 10.9478 21.3855 11.4479 21.8856C11.948 22.3857 12.6263 22.6667 13.3335 22.6667H21.3335M10.6668 9.33333V6.66667C10.6668 5.95942 10.9478 5.28115 11.4479 4.78105C11.948 4.28095 12.6263 4 13.3335 4H19.4482C19.8018 4.00008 20.1408 4.1406 20.3908 4.39067L26.2762 10.276C26.5262 10.526 26.6668 10.8651 26.6668 11.2187V20C26.6668 20.7072 26.3859 21.3855 25.8858 21.8856C25.3857 22.3857 24.7074 22.6667 24.0002 22.6667H21.3335M10.6668 9.33333H8.00016C7.29292 9.33333 6.61464 9.61428 6.11454 10.1144C5.61445 10.6145 5.3335 11.2928 5.3335 12V25.3333C5.3335 26.0406 5.61445 26.7189 6.11454 27.219C6.61464 27.719 7.29292 28 8.00016 28H18.6668C19.3741 28 20.0524 27.719 20.5524 27.219C21.0525 26.7189 21.3335 26.0406 21.3335 25.3333V22.6667"
                            stroke="#606469"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </svelte:fragment>
            </SidebarItem>
            <SidebarItem label="슈퍼어드민" href={transformUrl('/su/')}>
                <svelte:fragment slot="icon">
                    <svg width="1.125rem" height="1.125rem" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.6668 9.33333V20C10.6668 20.7072 10.9478 21.3855 11.4479 21.8856C11.948 22.3857 12.6263 22.6667 13.3335 22.6667H21.3335M10.6668 9.33333V6.66667C10.6668 5.95942 10.9478 5.28115 11.4479 4.78105C11.948 4.28095 12.6263 4 13.3335 4H19.4482C19.8018 4.00008 20.1408 4.1406 20.3908 4.39067L26.2762 10.276C26.5262 10.526 26.6668 10.8651 26.6668 11.2187V20C26.6668 20.7072 26.3859 21.3855 25.8858 21.8856C25.3857 22.3857 24.7074 22.6667 24.0002 22.6667H21.3335M10.6668 9.33333H8.00016C7.29292 9.33333 6.61464 9.61428 6.11454 10.1144C5.61445 10.6145 5.3335 11.2928 5.3335 12V25.3333C5.3335 26.0406 5.61445 26.7189 6.11454 27.219C6.61464 27.719 7.29292 28 8.00016 28H18.6668C19.3741 28 20.0524 27.719 20.5524 27.219C21.0525 26.7189 21.3335 26.0406 21.3335 25.3333V22.6667"
                            stroke="#606469"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </svelte:fragment>
            </SidebarItem>
        </SidebarGroup>
    </SidebarWrapper>
</Sidebar>
